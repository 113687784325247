@font-face {
    font-family: 'icomoon';
    src: url('/fonts/icomoon.eot?n8c1kw');
    src: url('/fonts/icomoon.eot?n8c1kw#iefix') format('embedded-opentype'),
        url('/fonts/icomoon.ttf?n8c1kw') format('truetype'),
        url('/fonts/icomoon.woff?n8c1kw') format('woff'),
        url('/fonts/icomoon.svg?n8c1kw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon', sans-serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-twitter:before {
    content: '\e943';
}
.icon-check:before {
    content: '\e93b';
}
.icon-more:before {
    content: '\e92d';
}
.icon-cross:before {
    content: '\e922';
}
.icon-menu1:before {
    content: '\e951';
}
.icon-right-icon:before {
    content: '\e917';
}
.icon-cross1:before {
    content: '\e918';
}
.icon-document:before {
    content: '\e919';
}
.icon-education:before {
    content: '\e91a';
}
.icon-triangle-right:before {
    content: '\e916';
}
.icon-add:before {
    content: '\e902';
}
.icon-add-2:before {
    content: '\e903';
}
.icon-alaram:before {
    content: '\e904';
}
.icon-all-domains:before {
    content: '\e905';
}
.icon-bag:before {
    content: '\e906';
}
.icon-content:before {
    content: '\e907';
}
.icon-custom-models:before {
    content: '\e908';
}
.icon-envelop:before {
    content: '\e909';
}
.icon-healthcare:before {
    content: '\e90a';
}
.icon-key:before {
    content: '\e90b';
}
.icon-notification:before {
    content: '\e90c';
}
.icon-real-estate:before {
    content: '\e90d';
}
.icon-restaurants:before {
    content: '\e91b';
}
.icon-tick:before {
    content: '\e91c';
}
.icon-arrow-right-circle:before {
    content: '\e900';
}
.icon-arrow-left-circle:before {
    content: '\e901';
}
.icon-arrow_right:before {
    content: '\e90e';
}
.icon-arrow_left:before {
    content: '\e90f';
}
.icon-arrow_drop_up:before {
    content: '\e910';
}
.icon-arrow_drop_down:before {
    content: '\e911';
}
.icon-keyboard_arrow_up:before {
    content: '\e912';
}
.icon-keyboard_arrow_right:before {
    content: '\e913';
}
.icon-keyboard_arrow_left:before {
    content: '\e914';
}
.icon-keyboard_arrow_down:before {
    content: '\e915';
}
