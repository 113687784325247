@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
}
html {
    @apply xl:text-sm;
}
@tailwind base;
@tailwind components;
/* ================
    Custom
=================== */
body {
    @apply overflow-x-hidden font-sans text-base antialiased font-normal bg-common-white text-interface-900 tablet:overflow-x-hidden;
}
.container {
    @apply relative mx-auto max-w-[1120px] md:max-w-[980px] lg:max-w-[92%] tablet:max-w-[100%] ipad:w-full ipad:px-6;
}
.container-sm {
    @apply max-w-[920px] mx-auto relative;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @apply text-interface-700;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
    /* Firefox 19+ */
    @apply text-interface-700;
}
input:-ms-input-placeholder,
textarea::-ms-input-placeholder {
    /* IE 10+ */
    @apply text-interface-700;
}
input:-moz-placeholder,
textarea::-moz-placeholder {
    /* Firefox 18- */
    @apply text-interface-700;
}
/* ============= Scrollbar ============= */
.ps::-webkit-scrollbar-track {
    @apply bg-transparent rounded-full;
}
.ps::-webkit-scrollbar {
    @apply w-1 h-2 rounded-full bg-common-white;
}
.ps::-webkit-scrollbar-thumb {
    @apply rounded-full bg-interface-300;
}
.ps__rail-y,
.ps__rail-x {
    @apply w-2 rounded bg-interface-600 !important;
}
.ps__thumb-y {
    @apply w-2 hover:w-2 bg-interface-400 right-0 !important;
}
p {
    @apply mb-4;
}
a {
    @apply text-primary-500;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    @apply mb-4 font-bold;
}
h1,
.h1 {
    @apply text-3xl border-opacity-100;
}
.h2,
h2 {
    @apply text-2xl;
}
.h3,
h3 {
    @apply text-xl;
}
.h4,
h4 {
    @apply text-lg;
}
.h5,
h5 {
    @apply text-base font-medium;
}
.h6,
h6 {
    @apply text-sm font-medium;
}
img {
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}
.text-gray {
    @apply text-gray-50;
}
/* =============
    Separator 
=============== */
.separator {
    @apply relative block w-full h-px border border-none bg-interface-200;
}
.separator.dark {
    @apply bg-interface-100;
}
/* =============
    Separator with text
=============== */
.separator-with-text {
    @apply relative flex items-center justify-center w-full h-5 mb-6;
}
.separator-with-text::after {
    content: '';
    height: 1px;
    @apply absolute left-0 right-0 w-full bg-interface-300;
}
.separator-with-text span {
    @apply relative z-10 p-4;
}
/* =============
    Button global css
=============== */
.btn.primary {
    @apply text-white border border-transparent bg-primary-600;
}
.btn.outline {
    @apply border border-opacity-100 text-primary-600 border-primary-600 bg-primary-50 hover:border-primary-600 hover:bg-primary-50 hover:text-primary-600 focus:border-primary-600 focus:bg-primary-50 focus:text-primary-600;
}
.btn.default {
    @apply bg-transparent border border-opacity-100 text-interface-700 border-interface-300 hover:border-interface-300 hover:bg-primary-50 hover:text-interface-600 focus:border-interface-300 focus:bg-transparent focus:text-interface-700;
}
/* ==============
    Data sources table
================= */
.data-table {
    @apply relative;
}
.data-table .card-body {
    @apply p-0;
}
.data-table ul {
    @apply relative;
}
.data-table ul li {
    @apply flex w-full py-4 text-sm border-b border-interface-100;
}
.data-table ul li span {
    @apply w-3/6;
}
.data-table ul li span:last-child {
    @apply text-right;
}
.data-table button {
    @apply px-4;
}
/* =============
    Steps Navigation
=============== */
.step-navigation {
    @apply grid h-20 grid-cols-3 px-6 py-4 border rounded-lg mb-7 border-interface-300;
}
.step-navigation .item {
    @apply flex items-center font-medium text-interface-500;
}
.step-navigation .count {
    @apply flex items-center justify-center flex-shrink-0 w-10 h-10 mr-4 text-sm font-medium border-2 rounded-full text-interface-500 border-interface-300;
}
.step-navigation .count i {
    @apply hidden;
}
/* Active State */
.step-navigation .item.active {
    @apply text-primary-500;
}
.step-navigation .item.active .count {
    @apply border-primary-500 text-primary-500;
}
/* Completed State */
.step-navigation .item.completed {
    @apply text-primary-500;
}
.step-navigation .item.completed .count {
    @apply border-primary-500 text-common-white bg-primary-500;
}
.step-navigation .item.completed .count span {
    @apply hidden;
}
.step-navigation .item.completed .count i {
    @apply block;
}
/* ==============
    Pages
================= */
.sidebar {
    width: 256px;
    @apply relative flex-shrink-0 h-auto mr-6;
}
.right-container {
    @apply relative w-full;
}
/* ==============
    Tabs
================= */
.tabsContainer {
    @apply inline-block w-auto border rounded-lg mb-7 border-interface-300 bg-gray-50;
}
.tabList {
    @apply flex items-center overflow-hidden rounded-md text-interface-500;
}
.tab {
    @apply px-4 py-2 font-medium text-center border-r border-gray-300 cursor-pointer;
}
.tab.react-tabs__tab--selected {
    @apply text-gray-900 bg-common-white;
}
.input-shadow-none.input-shadow-none {
    @apply shadow-none focus:shadow-none;
}
/* ==============
    Alert
================= */
.alert {
    @apply min-h-[44px];
}
.alert svg {
    @apply relative flex-shrink-0 mt-0 ml-0 mr-2;
}
.alert.success {
    @apply border bg-success-50 border-success-500 text-success-900;
}
.alert.danger {
    @apply border bg-danger-100 border-danger-500 text-danger-900;
}
/* ==============
    Sections
================= */
.section {
    @apply py-24 lg:py-14;
}
.section .heading {
    @apply text-3xl text-primary-900 max-w-[900px] mx-auto text-center mb-6 font-bold md:max-w-[750px] tablet:text-2xl;
}
.section .heading.text-common-white {
    @apply text-common-white;
}
.section .heading.dark {
    @apply ipad:text-primary-900;
}
.section .heading.left {
    @apply text-left;
}
.section .description {
    @apply text-base text-interface-900 max-w-[958px] mx-auto text-center mb-11 font-normal md:max-w-[750px];
}
/* ==============
    Footer
================= */
.footer .footerNavigation ul li ul {
    display: none;
}
.footer.footer ul li ul {
    @apply hidden;
}
.footer-links ul li a {
    @apply text-interface-200;
}
.section .description.text-common-white {
    @apply text-common-white;
}
/* ==============
    Banner
================= */
.banner {
    background-size: auto 100%;
    @apply max-w-[1320px] lg:max-w-[1130px] mx-auto text-center text-common-white rounded-xl overflow-hidden py-24 relative min-h-[500px] lg:min-h-[350px] md:max-w-[980px] ipad:max-w-[96%] ipad:min-h-0 ipad:py-9 tablet:max-w-full tablet:rounded-none;
}
.banner .inner {
    @apply max-w-[800px] mx-auto ipad:px-6;
}
.banner .bg-image {
    @apply absolute -top-24 left-0 right-0 w-full h-full mx-auto max-w-[800px] pointer-events-none;
}
.banner .bg-image img {
    @apply w-auto h-full;
}
.banner h1 {
    @apply mb-8 text-5xl ipad:text-4xl tablet:text-3xl;
}
.banner h3 {
    @apply ipad:text-lg ipad:max-w-[70%] ipad:mx-auto tablet:max-w-full;
}
.banner p {
    @apply mb-8 text-lg font-normal;
}
.banner .tagline {
    @apply block mb-4 text-xl font-semibold uppercase text-common-white tablet:text-lg;
}
.banner.landing {
    @apply py-0 bg-interface-100;
}
.banner.landing h1 {
    @apply text-4xl;
}
.banner.about {
    @apply bg-gradient-to-t from-primary-900 to-primary-500;
}
.banner.insights {
    background-color: #00003c;
    @apply bg-center bg-no-repeat;
}
.banner.insights .inner {
    @apply max-w-[843px];
}
/* ==============
    Card
================= */
.base-card.base-card {
    @apply text-left shadow-none bg-interface-100 rounded-xl;
}
.base-card .card-body {
    @apply p-6 tablet:flex tablet:p-4;
}
.base-card .card-body .image {
    @apply block mb-4 tablet:flex-shrink-0 tablet:mr-4;
}
.base-card .card-body .title {
    @apply mb-4 text-xl font-bold text-primary-900;
}
.base-card.base-card.dark {
    @apply bg-secondary;
}
.base-card.base-card.dark .title,
.base-card.base-card.dark .detail-content {
    @apply text-common-white;
}
.base-card.solutions .card-body .title {
    @apply mb-0;
}
.how-its-work-card {
    @apply text-center rounded-xl ipad:shadow-none p-10 ipad:p-0 bg-primary-500 ipad:bg-transparent h-[316px] lg:h-[280px] ipad:h-auto;
}
.how-its-work-card h2 {
    @apply text-3xl text-common-white ipad:text-primary-900;
}
/* Insights Details */
.insights-detail-content {
    @apply relative  max-w-[820px] mx-auto ipad:max-w-full ipad:px-10 tablet:px-0;
}
.insights-detail-content h1,
.insights-detail-content h2,
.insights-detail-content h3,
.insights-detail-content h4,
.insights-detail-content h5,
.insights-detail-content h6 {
    @apply text-primary-900;
}
.insights-detail-content h2,
.insights-detail-content p,
.insights-detail-content img {
    @apply mb-6;
}
.insights-detail-content h1 {
    @apply text-5xl tablet:text-4xl;
}
.insights-detail-content h2 {
    @apply text-3xl mt-11 tablet:text-2xl;
}
.insights-detail-content h3 {
    @apply text-2xl;
}
.insights-detail-content h4 {
    @apply text-xl;
}
.insights-detail-content h5 {
    @apply text-lg;
}
.insights-detail-content h6 {
    @apply text-base;
}
.insights-detail-content p {
    @apply text-base;
}
.insights-detail-content .container {
    @apply max-w-[640px] mx-auto;
}
/* Social Share */
.social-share {
    @apply flex block mb-10;
}
.social-share li,
.social-share li button {
    @apply w-[42px] h-[42px] mr-4 relative;
}
/* ==============
    Static Pages
================= */
.static-pages {
    @apply py-10;
}
.static-pages h1 {
    @apply mb-6 text-4xl text-primary-900;
}
.static-pages h3 {
    @apply mb-2 text-2xl text-primary-900;
}
.static-pages p {
    @apply mb-6 text-base font-normal text-interface-900;
}
.static-pages ul,
.static-pages ol {
    @apply mb-6 ml-10 list-disc list-outside;
}
.static-pages ul li,
.static-pages ol li {
    @apply mb-4;
}
.static-pages ol {
    @apply list-decimal;
}
.banner-component br {
    @apply ipad:hidden;
}
.base-card.contact-us-card {
    @apply shadow mb-9 bg-interface-100;
}
.base-card.contact-us-card h2 {
    @apply mb-3 text-2xl;
}
.base-card.contact-us-card p {
    @apply mb-6;
}
.modalHeader header {
    @apply relative top-5 right-5;
}
.modalHeader header button svg {
    @apply w-24 h-24;
}
/*
    Error page
*/
.error-page {
    @apply min-h-[calc(100vh-466px)] lg:min-h-[calc(100vh-379px)] tablet:min-h-0  flex justify-center items-center flex-col;
}
.error-page h1 {
    font-size: 180px;
    line-height: 180px;
    @apply text-purple-900 tablet:text-9xl;
}
.error-page h2 {
    @apply text-4xl tablet:text-3xl;
}
/*
    Promo Video
*/
.intro-video {
    @apply absolute top-0 left-0 w-full h-full;
}
.intro-video iframe {
    @apply absolute top-0 left-0 w-full h-full;
}
/*
    nProgress bar
*/
#nprogress .bar {
    @apply bg-primary-500;
}
#nprogress .spinner {
    @apply hidden;
}

@tailwind utilities;
