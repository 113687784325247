.btn {
    font-family: Inter, Arial, sans-serif;
    @apply relative flex items-center justify-center overflow-hidden text-sm font-medium text-center transition-all border border-gray-300 rounded-md h-11 focus:ring-0 text-interface-700;
}
.btn.lg {
    @apply px-6 text-xl font-medium h-14;
}
.primary {
    @apply transition-all border-transparent bg-primary-500 text-common-white hover:bg-primary-700;
}
.secondary {
    @apply text-common-white bg-secondary;
}
.outline {
    @apply border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-common-white;
}
.default {
    @apply border-none bg-common-white text-primary-500;
}
.tweet {
    background-color: #1da1f2;
    @apply border-transparent text-common-white;
}
.btn .labelText {
    font-family: Inter, Arial, sans-serif;
    @apply font-medium;
}
.btn i {
    @apply mr-3 text-lg;
}
.btn .iconPosition.right {
    @apply flex-row-reverse;
}
.btn .iconPosition.right i {
    @apply ml-3 mr-0;
}
.btn .spinner {
    @apply absolute left-0 right-0 w-6 m-auto opacity-0 top-2;
}
.btn.loading,
.btn[loading] {
    @apply text-transparent border-transparent pointer-events-none bg-interface-400;
}
.btn.loading .spinner {
    @apply opacity-100;
}
.btn.loading .labelText {
    @apply opacity-0;
}
.btn.disabled,
.btn[disabled] {
    @apply pointer-events-none opacity-70;
}
