@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter.eot');
    src: url('/fonts/Inter.eot') format('embedded-opentype'),
        url('/fonts/Inter.ttf') format('truetype'),
        url('/fonts/Inter.woff') format('woff'),
        url('/fonts/Inter.woff2') format('woff2'),
        url('/fonts/Inter.svg') format('svg');
    font-weight: 100 1000;
    font-stretch: 25% 151%;
    /* src: url('~assets/fonts/Inter.woff2') format('woff2'); */
}
