.header {
    @apply relative z-40 h-16 mb-8 tablet:mb-0 tablet:-top-0.5 tablet:shadow;
}
.headerShadow {
    @apply shadow;
}
.header .inner {
    @apply fixed top-0 flex w-full h-16 bg-common-white;
}
.header .logo {
    @apply flex-shrink-0 w-[246.08px] h-[42px] tablet:w-[230px];
}
.header .navigation {
    @apply flex items-center ml-auto;
}
.header .showMenu.navigation {
    @apply tablet:flex;
}
.header .mobileIcon {
    @apply absolute hidden text-4xl rounded-md right-4 top-3 tablet:flex flex-col bg-primary-500 w-[36px] h-[32px] px-1.5  justify-center items-center text-common-white;
}
.header .mobileIcon i {
    @apply relative top-[1px];
}
.mobileScroll {
    @apply hidden tablet:block;
    height: calc(100vh - 165px);
}
@media screen and (max-width: 767px) {
    .header .navigation {
        @apply fixed left-0 right-0 w-full  pt-3 border-t border-interface-200 flex flex-col-reverse items-baseline justify-end h-full mx-auto ml-0 top-[56px] bg-interface-100 hidden;
    }
}
