.navigation {
    @apply relative;
}
.navigation ul {
    @apply relative flex justify-end;
}
.navigation ul li {
    @apply relative flex items-center h-16;
}
.navigation ul li a {
    @apply px-4 py-3 text-sm font-medium text-interface-900;
}
.navigation ul li a i {
    @apply text-sm relative rotate-90 inline-block ml-3 top-0.5;
}
.navigation ul li a.active {
    @apply text-purple-500;
}
.footer .navigation ul li a {
    @apply px-4 text-base font-normal text-primary-50;
}
.navigation ul li a.active:after {
    content: '';
    @apply absolute bottom-0 w-full h-0.5 bg-primary-500 hidden;
}
.navigation ul li.hide {
    @apply hidden;
}
/* ====
    Sub navigation
==== */
.navigation .subNavigation {
    margin-left: -147px;
    @apply absolute w-[768px] lg:w-[700px] h-auto top-16   rounded-xl shadow  left-0   bg-common-white border border-interface-100 opacity-0 transition-all pointer-events-none;
}
@media only screen and (max-width: 1023px) {
    .navigation .subNavigation {
        margin-left: -265px;
    }
}
.navigation ul li:hover .subNavigation {
    @apply opacity-100 pointer-events-auto;
}
.navigation .subNavigation ul {
    @apply relative grid grid-cols-2 p-8 pt-5 -mb-3;
}
.navigation .subNavigation ul li {
    @apply w-full h-auto;
}
.navigation .subNavigation ul li a {
    @apply w-full px-0 py-4 pr-3 text-primary-50;
}
.navigation .subNavigation ul li a h5 {
    @apply mb-1 text-base font-semibold text-interface-900;
}
.navigation .subNavigation ul li a P {
    @apply mb-0 text-sm font-normal text-interface-700;
}
.subNavigationFooter {
    @apply p-8 border-t rounded-b-xl bg-interface-50 border-interface-200;
}
/* ====
  Footer sub navigation
==== */
.footer .navigation .subNavigation {
    margin-left: -222px;
    @apply left-0 top-auto bottom-16;
}
@media only screen and (max-width: 1023px) {
    .footer .navigation .subNavigation {
        margin-left: -55px;
    }
}
/* ====
    Mobile Header navigation
==== */
@media screen and (max-width: 767px) {
    .headerNavigation.navigation {
        @apply px-3;
    }
    .headerNavigation.navigation ul {
        @apply flex flex-col;
    }
    .headerNavigation.navigation ul li {
        @apply block w-full h-auto;
    }
    .headerNavigation.navigation ul li:last-child {
        @apply mobile:hidden;
    }
    .headerNavigation.navigation ul li a {
        @apply block h-auto text-lg font-normal;
    }
    .headerNavigation.navigation ul li:first-child a {
        @apply pb-0;
    }
    .headerNavigation.navigation .subNavigation {
        @apply relative top-0 left-0 block w-auto h-auto grid-cols-1 mx-auto mb-0 overflow-hidden bg-transparent border-none rounded-none shadow-none opacity-100 pointer-events-auto;
    }
    .headerNavigation.navigation .subNavigation ul li {
        @apply flex;
    }
    .headerNavigation.navigation .subNavigation ul li a {
        @apply flex py-3 first-of-type:pt-0;
    }
    .headerNavigation.navigation .subNavigation ul li span:first-child {
        @apply inline-block w-[28px];
    }
    .headerNavigation.navigation .subNavigation ul li:last-child a {
        @apply pb-0 -mb-2;
    }
    .headerNavigation.navigation .subNavigation .subNavigationFooter {
        @apply hidden;
    }
}
/* ====
    Mobile footer navigation
==== */
@media screen and (max-width: 767px) {
    .footerNavigation .navigation ul {
        @apply flex-col mt-3 text-center;
    }
    .footerNavigation .navigation ul li {
        @apply block h-auto px-0 mx-auto my-3;
    }
    .footerNavigation .navigation .subNavigation {
        @apply relative top-0 left-0 w-auto h-0 grid-cols-1 mx-auto mb-0 overflow-hidden bg-transparent border-none rounded-none shadow-none;
    }
    .footerNavigation .navigation .subNavigation ul {
        @apply grid-cols-1 p-0 pl-5 text-left;
    }
    .footerNavigation .navigation .subNavigation ul li {
        @apply my-0;
    }
    .footerNavigation .navigation .subNavigation ul li a {
        @apply justify-center py-2 text-interface-100;
    }
    .footerNavigation .navigation .subNavigation ul li a span:first-child {
        @apply hidden;
    }
    .footerNavigation .navigation .subNavigation ul li a h5 {
        @apply font-normal text-interface-200;
    }
    .footerNavigation .navigation .subNavigation ul li a span p {
        @apply hidden;
    }
    .footerNavigation .navigation .subNavigation li {
        @apply h-auto;
    }
    .footerNavigation .navigation .subNavigation .subNavigationFooter {
        @apply hidden;
    }
    /* Hover */
    .footerNavigation .navigation ul li:hover .subNavigation {
        @apply h-auto;
    }
    .footerNavigation .navigation ul li .subNavigation {
        @apply mobile:h-auto opacity-95;
    }
}
